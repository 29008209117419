import Vue from 'vue';
import axios from '@/const/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import 'normalize.css/normalize.css';
import ElementUI from 'element-ui';
import '@/assets/theme/index.css';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

Vue.use(ElementUI);

Vue.prototype.$http = axios;
window.$http = axios;


Vue.config.productionTip = false;


new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');

import axios from 'axios';
import { MessageBox, Notification } from 'element-ui';
import $router from '@/router';

// create an axios instance
const http = axios.create({
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 300000, // request timeout
});

// login interceptor
http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const message = error?.response?.message;

        return error.response;
    },
);

export default http;
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "admin" */ '@/layout/admin'),
        redirect: {
            name: 'Home',
        },
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

export default router;
